var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-toolbar", {
    attrs: { searchable: "", search: _vm.searchText },
    on: {
      "update:search": function($event) {
        _vm.searchText = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "mobile-search-field",
        fn: function() {
          return [
            _c(
              "v-menu",
              {
                attrs: { "offset-y": "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-text-field",
                          _vm._g(
                            {
                              staticClass: "mobile-search",
                              attrs: {
                                dense: "",
                                width: "100%",
                                label: _vm.$t("searchInLabel", {
                                  pageName: _vm.localTitle
                                }),
                                solo: "",
                                flat: "",
                                "hide-details": "",
                                "single-line": ""
                              },
                              model: {
                                value: _vm.searchText,
                                callback: function($$v) {
                                  _vm.searchText = $$v
                                },
                                expression: "searchText"
                              }
                            },
                            on
                          )
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _vm.searchText !== ""
                  ? _c(
                      "v-list",
                      { attrs: { dense: "" } },
                      [
                        _c(
                          "v-list-item",
                          { on: { click: _vm.searchForAllTeacherFiles } },
                          [
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("resourcesSearchMsg", {
                                      text: _vm.searchText
                                    })
                                  ) +
                                  " "
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "toolbar-left",
        fn: function() {
          return [
            _vm.openedFolderCounter !== 0 && !_vm.editMode
              ? _c(
                  "pb-btn",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      color: "primary",
                      label: _vm.$t("upOneFolderLabel"),
                      text: "",
                      outlined: "",
                      disabled: _vm.isTableLoading
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.upOneFolder.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("v-icon", { staticClass: "done-icon" }, [
                      _vm._v("fal fa-folder-arrow-up")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-col",
              { staticClass: "px-0", attrs: { md: "2", sm: "3" } },
              [
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                {
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    clearable: "",
                                    "single-line": "",
                                    "hide-details": "",
                                    "prepend-inner-icon": "fal fa-search",
                                    label: _vm.$t("searchLabel")
                                  },
                                  model: {
                                    value: _vm.searchText,
                                    callback: function($$v) {
                                      _vm.searchText = $$v
                                    },
                                    expression: "searchText"
                                  }
                                },
                                on
                              )
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _vm.searchText !== ""
                      ? _c(
                          "v-list",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-list-item",
                              { on: { click: _vm.searchForAllTeacherFiles } },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("resourcesSearchMsg", {
                                          text: _vm.searchText
                                        })
                                      ) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "toolbar-right",
        fn: function() {
          return [
            !_vm.editMode
              ? _c(
                  "v-menu",
                  {
                    attrs: { transition: "scroll-y-transition", offset: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var onMenu = ref.on
                            return [
                              _c(
                                "pb-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-2 my-1",
                                    attrs: {
                                      color: "primary",
                                      label: _vm.$t("addLabel"),
                                      text: "",
                                      outlined: ""
                                    }
                                  },
                                  Object.assign({}, onMenu)
                                ),
                                [_c("v-icon", [_vm._v("fal fa-plus")])],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1466624245
                    )
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                _vm.openSubPage(_vm.$t("createFolderLabel"))
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "drop-down-list" },
                              [_vm._v(_vm._s(_vm.$t("createFolderLabel")))]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.uploadFilesOnClick } },
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "drop-down-list" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("fileUploadLabel")) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : [
                  _vm.hasSelectedRows
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "ml-2 my-1",
                          attrs: {
                            color: "error",
                            label: _vm.$t("deleteLabel"),
                            text: "",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.deleteMultipleDialog.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                        1
                      )
                    : _vm._e(),
                  _c("folder-picker", {
                    attrs: {
                      moveToCurrentOpenedFolder: _vm.moveToCurrentOpenedFolder,
                      folders: _vm.filteredAllAvailableFolders,
                      isTableLoading: _vm.isTableLoading,
                      moveToFolder: _vm.moveToFolder,
                      moveToOpenedFolder: _vm.moveToOpenedFolder,
                      hasSelectedRows: _vm.hasSelectedRows,
                      moveToIsAddingFolder: _vm.moveToIsAddingFolder,
                      moveToNewFolderName: _vm.moveToNewFolderName
                    },
                    on: {
                      openMoveToFolder: _vm.openMoveToFolder,
                      moveToCreateNewFolder: _vm.moveToCreateNewFolder,
                      doMove: _vm.doMove,
                      "update:moveToFolder": function($event) {
                        _vm.moveToFolder = $event
                      },
                      "update:moveToOpenedFolder": function($event) {
                        _vm.moveToOpenedFolder = $event
                      },
                      "update:moveToIsAddingFolder": function($event) {
                        _vm.moveToIsAddingFolder = $event
                      },
                      "update:moveToNewFolderName": function($event) {
                        _vm.moveToNewFolderName = $event
                      }
                    },
                    model: {
                      value: _vm.moveMenu,
                      callback: function($$v) {
                        _vm.moveMenu = $$v
                      },
                      expression: "moveMenu"
                    }
                  }),
                  _c(
                    "pb-btn",
                    {
                      staticClass: "ml-2 my-1",
                      attrs: {
                        color: "primary",
                        label: _vm.$t("doneLabel"),
                        text: "",
                        outlined: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleEditMode.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "done-icon" }, [
                        _vm._v("fal fa-pen-slash")
                      ])
                    ],
                    1
                  )
                ],
            !_vm.editMode
              ? _c(
                  "v-menu",
                  {
                    attrs: { bottom: "", "offset-y": "", "close-on-click": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var onMenu = ref.on
                            return [
                              _c(
                                "pb-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-2 my-1",
                                    attrs: {
                                      color: "primary",
                                      label: _vm.$t("actionsLabel"),
                                      text: "",
                                      outlined: ""
                                    }
                                  },
                                  Object.assign({}, onMenu)
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v("fal fa-ellipsis-stroke-vertical")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      648777904
                    )
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          { on: { click: _vm.toggleEditMode } },
                          [
                            _c(
                              "v-btn",
                              { attrs: { text: "", color: "primary" } },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-pen-to-square")
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("manageLabel")))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("pb-confirm", { ref: "resourcesConfirm" })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }