



































































































































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import CommonUtils from '@/utils/common-utils';
import { Component } from 'vue-property-decorator';
import FolderPicker from './FolderPicker.vue';

@Component({
  components: {
    BaseToolbar,
    FolderPicker
  }
})
export default class ResourcesToolbar extends BaseToolbar {
  get localComponent(): any {
    return this.currentPageInstance || {};
  }

  get openSubPage() {
    return this.localComponent.openSubPage || this.emptyFunction;
  }

  get fileDialog() {
    return this.localComponent.fileDialog;
  }

  set fileDialog(value: boolean) {
    this.localComponent.fileDialog = value;
  }

  get filesUploadingArray() {
    return this.localComponent.filesUploadingArray || [];
  }

  set filesUploadingArray(value: Array<any>) {
    this.localComponent.filesUploadingArray = value;
  }

  get errorUploadFile() {
    return this.localComponent.errorUploadFile;
  }

  set errorUploadFile(value: boolean) {
    this.localComponent.errorUploadFile = value;
  }

  get toggleEditMode() {
    return this.localComponent.toggleEditMode || this.emptyFunction;
  }

  get editMode() {
    return this.localComponent.editMode;
  }

  get dialog() {
    return this.localComponent.dialog;
  }

  set dialog(value: boolean) {
    this.localComponent.dialog = value;
  }

  get CreateFolder() {
    return this.localComponent.CreateFolder || this.emptyFunction;
  }

  get breadCrumbsData() {
    return this.localComponent.breadCrumbsData || [];
  }

  get hasSelectedRows() {
    return CommonUtils.isNotEmpty(this.selectedRows);
  }

  get isNotEmpty() {
    return CommonUtils.isNotEmpty;
  }

  get newFolderName() {
    return this.localComponent.newFolderName || '';
  }

  set newFolderName(value: string) {
    this.localComponent.newFolderName = value;
  }

  get errorUploadFolderField() {
    return this.localComponent.errorUploadFolderField || '';
  }

  get saveFile() {
    return this.localComponent.saveFile || this.emptyFunction;
  }

  get files() {
    return this.localComponent.files || [];
  }

  set files(value: Array<any>) {
    this.localComponent.files = value;
  }

  get changeFileName() {
    return this.localComponent.changeFileName || this.emptyFunction;
  }

  get renameFileDialog() {
    return this.localComponent.renameFileDialog;
  }

  set renameFileDialog(value: boolean) {
    this.localComponent.renameFileDialog = value;
  }

  get confirmEditFile() {
    return this.localComponent.confirmEditFile || this.emptyFunction;
  }

  get newEditedFileName() {
    return this.localComponent.newEditedFileName || '';
  }

  set newEditedFileName(value: string) {
    this.localComponent.newEditedFileName = value;
  }

  get errorNewEditFileName() {
    return this.localComponent.newEditedFileName || '';
  }

  get selectedRows() {
    return this.localComponent.selectedRows || [];
  }

  get deleteMultipleDialog() {
    return this.localComponent.deleteMultipleDialog || this.emptyFunction;
  }

  get openedFolderCounter() {
    return this.localComponent.openedFolderCounter || 0;
  }

  get upOneFolder() {
    return this.localComponent.upOneFolder || this.emptyFunction;
  }

  get searchText() {
    return this.localComponent.search || '';
  }

  set searchText(value: string) {
    this.localComponent.search = value;
  }

  get searchForAllTeacherFiles() {
    return this.localComponent.searchForAllTeacherFiles || this.emptyFunction
  }

  get showingAllFiles() {
    return this.localComponent.showingAllFiles || false
  }

  get moveFiles() {
    return this.localComponent.moveFiles || this.emptyFunction;
  }

  get moveMenu() {
    return this.localComponent.moveMenu;
  }

  set moveMenu(value: boolean) {
    this.localComponent.moveMenu = value;
  }

  get allFoldersAvailable() {
    return this.localComponent.allFoldersAvailable || [];
  }

  get moveToFolder() {
    return this.localComponent.moveToFolder;
  }

  set moveToFolder(value: any) {
    this.localComponent.moveToFolder = value;
  }

  get isMoveToFolderEmpty() {
    return CommonUtils.isEmpty(this.moveToFolder);
  }

  get openMoveToFolder() {
    return this.localComponent.openMoveToFolder || this.emptyFunction;
  }

  get filteredAllAvailableFolders() {
    return this.localComponent.filteredAllAvailableFolders || [];
  }

  get moveToOpenedFolder () {
    return this.localComponent.moveToOpenedFolder || []
  }

  set moveToOpenedFolder(value: any) {
    this.localComponent.moveToOpenedFolder = value;
  }

  get moveToCurrentOpenedFolder() {
    return this.localComponent.moveToCurrentOpenedFolder || {}
  }

  get moveToIsAddingFolder() {
    return this.localComponent.moveToIsAddingFolder || false;
  }

  set moveToIsAddingFolder(val:boolean) {
    this.localComponent.moveToIsAddingFolder = val;
  }

  get moveToCreateNewFolder() {
    return this.localComponent.moveToCreateNewFolder || this.emptyFunction;
  }

  get moveToNewFolderName() {
    return this.localComponent.moveToNewFolderName || '';
  }

  set moveToNewFolderName(val:string) {
    this.localComponent.moveToNewFolderName = val;
  }

  get doMove() {
    return this.localComponent.doMove || this.emptyFunction;
  }

  get isTableLoading() {
    return this.localComponent.isTableLoading || false;
  }

  get uploadFilesOnClick () {
    return this.localComponent.uploadFilesOnClick || this.emptyFunction;
  }
}
